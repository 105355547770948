module.exports = {
  chenggong: '成功',
  shibai: '失败',
  shi: '是',
  fou: '否',
  beizhu: '备注',
  caozuo: '操作',
  xinzeng: '新增',
  bianji: '编辑',
  shanchu: '删除',
  bizhong: '币种',
  queding: '确定',
  quxiao: '取消',
  tijiao: '提交',
  chongzhi: '重置',
  tishi: '提示',
  shanchutips: '此操作将永久删除该内容, 是否继续?',
  qxz: '请选择',
  qsr: '请输入',
  flmc: '分类名称',
  yhm: 'UID',
  zhuangtai: '状态',
  cjsj: '创建时间',
  xxsh: '信息审核',
  tongguo: '通过',
  bohui: '驳回',
  wallettype: '钱包类型',
  withdraw_fee: '手续费',
	
	"银行卡": "银行卡",
	"开户姓名": "开户姓名",
	"银行卡账号": "银行卡账号",
	"开户银行": "开户银行",
	"开户支行": "开户支行",
	"提币数量": "提币数量",
	"提币地址": "提币地址",
	"声音提醒": "声音提醒",
	"开启":"开启",
	"关闭":"关闭",
	
	
  xgmm: {
    grxx: '个人信息',
    jmm: '旧密码',
    jmmts: '请输入旧密码',
    xmm: '新密码',
    xmmts: '请输入新密码',
    zcsrmm: '再次输入密码',
    zcmmts: '请再次输入密码',
    lcmmbyz: '两次密码不一致'
  },
  common: {
    home: '首页',
    xgmm: '修改密码',
    tcdl: '退出登录',
    cpgl: '产品管理',
    cplb: '产品列表',
    cplx: '产品类型',
    fkgl: '风控管理',
    cxdd: '创新订单',
    jyls: '交易流水',
    mmbgl: '买卖币管理',
    xxsh: '信息审核',
    jydw: '交易单位',
    jjgl: '基金管理',
    lcjj: '理财基金',
    jjfb: '基金发布',
    lcjl: '理财记录',
    jjtz: '基金投资',
    jjjs: '基金介绍',
    xyed: '信用额度',
    jjlx: '基金类型',
    yhgl: '用户管理',
    khlb: '客户列表',
    khye: '客户余额',
    khczjl: '客户操作记录',
    zcczjl: '资产操作记录',
    khyzmjl: '客户验证码记录',
    fkylxfs: '付款与联系方式',
    czlb: '充值列表',
    txlb: '提现列表',
    jcgl: '教程管理',
    jcfl: '教程分类',
    jclb: '教程列表',
    hqgl: '行情管理',
    xinwen: '新闻',
    xwtscssz: '新闻推送参数设置',
    qbgl: '钱包管理',
    qbxx: '钱包信息',
    qblx: '钱包类型',
    cssz: '参数设置',
    jcsz: '基础设置',
    lbtsz: '轮播图设置',
    ypsz: '音频设置',
    yxcssz: '邮箱参数设置',
    dxcssz: '短信参数设置',
    ipdzjxgl: 'IP地址解析管理',
    fkylxfssz: '付款与联系方式设置',
    xysz: '协议设置',
    xtsz: '系统设置',
    tjyhhgly: '添加用户或管理员',
    glylb: '管理员列表',
    quhao: '区号',
    jsgl: '角色管理'
  },
  home: {
    jrxzyh: '今日新增用户',
    zyh: '总用户',
    yhzye: '用户总余额',
    jrdd: '今日订单',
    khyk: '客户盈亏',
    jrls: '今日流水',
    jrcz: '今日充值',
    jrtx: '今日提现',
    dtsxf: '当天手续费',
    bianma: '编码',
    yhm: 'UID',
    gmdj: '购买单价',
    dqsj: '到期时间',
    lilv: '利率',
    gmzj: '购买总价',
    gmsye: '购买时余额',
    jssdj: '结束时单价',
    jsscsfy: '结束时产生费用',
    jshye: '结束后余额',
    zhuangtai: '状态',
    leixing: '类型',
    cjsj: '创建时间',
    jieduo: '借多',
    jiekong: '借空',
    ywc: '已完成',
    zzjx: '正在进行'
  },
  cp: {
    cpmc: '产品名称',
    cplx: '产品类型',
    zxz: '最小值',
    zdz: '最大值',
    sjbdfw: '随机波动范围',
    cpdm: '产品代码',
    suoxie: '缩写',
    ysdm: '颜色代码',
    sjwfjg: '时间玩法间隔',
    zdtzje: '最低投注金额',
    zgtzje: '最高投注金额',
    ykbl: '盈亏比例',
    zwjj: '中文简介',
    ywjj: '英文简介',
    fxsj: '发行时间',
    fxsl: '发行数量',
    fkbdfw: '风控波动范围',
    sfxs: '是否显示',
    sjtips:
      '注意：如时间为1分、3分、5分，则请用字母逗号将时间分开，如输入：1,3,5',
    zdtips:
      '注意：如最低投注金额为：200，300,400，则请用字母逗号将金额分开，如输入：200,300,400。必须对应时间设置，如果不设置默认使用设置的最后一个值补全',
    zgtips:
      '注意：如最高投注金额为：200，300,400，则请用字母逗号将金额分开，如输入：200,300,400。必须对应时间设置，如果不设置默认使用设置的最后一个值补全',
    yktips:
      '注意：如比例为：50%，75%,80%，则请用字母逗号将比例分开，如输入：50,75,80。必须对应时间设置，如果不设置默认使用设置的最后一个值补全',
    zhouyi: '周一',
    zhouer: '周二',
    zhousan: '周三',
    zhousi: '周四',
    zhouwu: '周五',
    zhouliu: '周六',
    zhouri: '周日'
  },
  fk: {
    zdkhks: '指定客户亏损',
    zdkhyl: '指定客户盈利',
    zxfkz: '最小风控值',
    fkgl: '风控概率',
    zdz: '最大值',
    zxz: '最小值',
    gailv: '概率',
    kstips:
      '说明：请输入用户名进行搜索并添加用户，设置后该用户所有借多、借空的订单都会亏损，请谨慎操作。如停止该功能请在上面留空并提交',
    yltips:
      '说明：请输入用户名进行搜索并添加用户，设置后该用户所有借多、借空的订单都会盈利，请谨慎操作。如停止该功能请在上面留空并提交',
    fktips:
      '说明：输入金额区间，在金额区间之内会根据此概率盈亏。不在此区间则不受风控影响。'
  },
  dd: {
    ztz: '总投资',
    dds: '订单数',
    yingkui: '盈亏',
    ddjxz: '该订单正在进行中',
    ddywc: '该订单已经完成'
  },
  mmb: {
    danhao: '单号',
    yjysl: '已交易数量',
    leixing: '类型',
    cplx: '产品类型',
    jydj: '交易单价',
    zdjysl: '最大交易数量',
    zxjysl: '最小交易数量',
    jyzl: '交易总量',
    jysl: '交易数量',
    zhuangtai: '状态',
    jyzt: '交易状态',
    quanbu: '全部',
    zzjx: '正在进行',
    jyqx: '交易取消',
    dsh: '待审核',
    ysh: '已审核',
    jujue: '拒绝',
    yjs: '已结束',
    yfk: '已付款',
    yfb: '已放币',
    ysb: '已收币',
    qrfk: '确认付款',
    jywc: '交易完成',
    mbqx: '卖币取消订单',
    yqx: '已取消',
    dfk: '待付款',
    dwfh: '单位符号',
    sfwxnyh: '是否为虚拟用户',
    fkylxfs: '付款与联系方式',
    lxdh: '联系电话',
    lxlx: '联系类型',
    lxxx: '联系信息',
    tongguo: '通过',
    gddzzjxz: '该订单正在进行中',
    gddyqx: '该订单已取消',
    gddyzf: '该订单已支付',
    gddyfb: '该订单已放币',
    gddysb: '该订单已收币',
    gddqrfk: '该订单已确认付款',
    gddmbqx: '该卖币订单已取消',
    gddywc: '该卖币订单已完成'
  },
  jj: {
    biaoti: '标题',
    sfzdyje: '是否自定义金额',
    sjjgwf: '时间间隔玩法',
    jejgwf: '金额间隔玩法',
    lilv: '利率',
    zongshu: '总数',
    keyong: '可用',
    yiyong: '已用',
    dongjie: '冻结',
    sfkq: '是否开启',
    cplx: '产品类型',
    leixing: '类型',
    neirong: '内容',
    sfyxbdqtyh: '是否允许绑定其他用户',
    bianma: '编码',
    tianshu: '天数',
    dqcjzs: '当前筹集总数',
    sjc: '时间差',
    mbsj: '满标时间',
    lcjj: '理财基金',
    shzt: '审核状态',
    qixian: '期限',
    cjyh: '创建用户',
    jjlx: '基金类型',
    zjts: '增加天数',
    xxsh: '信息审核',
    fbbm: '发布编码',
    fbzs: '发布总数',
    xypj: '信用评级',
    tzpj: '投资评级',
    sfrz: '身份认证',
    jgrz: '机构认证',
    fanxian: '返现',
    tzdh: '投资单号',
    czsj: '操作时间',
    stime: '购买时间',
    etime: '结束时间',
    shouyi: '收益',
    price: '购买金额',
    zhouqi: '周期',
    tupian: '图片',
    fmtp: '封面图片',
    xyedzj: '信用额度总计',
    kyxyed: '可用信用额度',
    ysyxyed: '已使用信用额度',
    djxyed: '冻结信用额度',
    eddw: '额度单位',
    xyed: '信用额度',
    ningcheng: '名称',
    sfqy: '是否启用',
    jjfl: '基金分类',
    mingcheng: '名称',
    tsts: '说明：在原有天数的基础上再增加几天 到期时间=原有天数+增加天数'
  },
  yh: {
    guojia: '国家',
    sjh: '手机号',
    youxiang: '邮箱',
    zsxm: '真实姓名',
    sfbs: '身份标识',
    fczh: '封存账号',
    zcsj: '注册时间',
    xxsh: '信息审核',
    yhfk: '用户风控',
    zccz: '资产操作',
    bdqb: '绑定钱包',
    zichan: '资产',
    czsl: '操作数量',
    xzjj: '选择加减',
    bibi: '币币',
    fabi: '法币',
    cplx: '产品类型',
    djzc: '冻结资产',
    djjdyy: '冻结/解冻原因',
    dongjie: '冻结',
    jiedong: '解冻',
    jbxx: '基本信息',
    shzt: '审核状态',
    zhdlsj: '最后登录时间',
    nicheng: '昵称',
    xyf: '信用分',
    sfrzxx: '身份认证信息',
    dizhi: '地址',
    gjrzxx: '高级认证信息',
    zhiye: '职业',
    shouru: '收入',
    lxdh: '联系电话',
    sfzzm: '身份证正面',
    sfzfm: '身份证反面',
    sczjzp: '手持证件照片',
    qtzm: '其他证明',
    zsyh: '真实用户',
    xnyh: '虚拟用户',
    kuisun: '亏损',
    zhengchang: '正常',
    yingli: '盈利',
    tjyh: '添加用户',
    yhlx: '用户类型',
    mima: '密码',
    bbdj: '币币冻结',
    fbdj: '法币冻结',
    zongji: '总计',
    ckxfjl: '查看消费记录',
    xfjl: '消费记录',
    chuangxin: '创新',
    cxdj: '创新冻结',
    czyy: '操作原因',
    czlx: '操作类型',
    gldh: '关联单号',
    czsj: '操作时间',
    dfdj: 'DeFi冻结',
    denglu: '登陆',
    dengchu: '登出',
    ipdz: 'IP地址',
    yhdlpt: '用户登录平台',
    gjdm: '国家代码',
    jingdu: '经度',
    weidu: '纬度',
    bianhao: '编号',
    czy: '操作员',
    yonghu: '用户',
    shuliang: '数量',
    sjyzm: '手机验证码',
    quhao: '区号',
    jssjh: '接收手机号',
    yzm: '验证码',
    sfsx: '是否失效',
    sxsj: '失效时间',
    yxyzm: '邮箱验证码',
    fsyx: '发送邮箱',
    jsyx: '接收邮箱',
    yhgl: '用户管理',
    lxlx: '联系类型',
    lxxm: '联系姓名',
    lxxx: '联系信息',
    yhk: '银行卡',
    khyh: '开户银行',
    khxm: '开户姓名',
    yhkh: '银行卡号',
    lxmc: '联系名称',
    zfb: '支付宝',
    qita: '其他',
    xxshzt: '信息审核状态',
    sqdh: '申请单号',
    txsl: '提现数量',
    txdz: '提现地址',
    quanbu: '全部',
    wrz: '未认证',
    sfrzz: '身份认证中',
    sfrz: '身份认证',
    gjrzz: '高级认证中',
    gjrz: '高级认证',
    sfsh: '身份审核',
    gjsh: '高级审核',
    czmm: '重置密码',
    gzhxwzc: '该账户下无资产',
    jia: '加',
    jian: '减',
    xiugai: '修改',
    shenhe: '审核',
    czbm: '充值编号',
    czlxing: '充值类型',
    qbdz: '钱包地址',
    capz: '充值凭证',
    czsliang: '充值数量',
    txbh: '提现编号',
    txlx: '提现类型'
  },
  jc: {
    jcfl: '教程分类',
    cjyh: '创建用户',
    biaoti: '标题',
    neirong: '内容'
  },
  hq: {
    biaoti: '标题',
    jianjie: '简介',
    fmtp: '封面图片',
    neirong: '内容',
    sfyjyx: '是否永久有效',
    gqsj: '过期时间',
    sfzjxts: '是否只进行推送',
    tsyh: '推送用户',
    sfgq: '是否过期',
    qdts: '启动推送'
  },
  qb: {
    qbdz: '钱包地址',
    qbdzewm: '钱包地址二维码',
    qblx: '钱包类型',
    cplx: '产品类型',
    yonghu: '用户',
    flmc: '分类名称'
  },
  cs: {
    wzmc: '网站名称',
    kfdz: '客服地址',
    lxmjdz: '汇率',
    gonggao: '公告',
    ggts:
      '注意：设置多条公告，请以|分割开，例如：公告内容1|公告内容2|公告内容3',
    wzsfgb: '网站是否关闭',
    sfkfzc: '是否开放注册',
    mbptsqsxf: '每笔平台收取手续费',
    tzje: '投资金额',
    zdxdje: '最低下单金额',
    zgxdje: '最高下单金额',
    sfkftx: '是否开放提现',
    mbtxsxf: '每笔提现手续费',
    dbzdtxje: '单笔最低提现金额',
    dbzgtxje: '单笔最高提现金额',
    drljzgtxje: '当日累计最高提现金额',
    mrzgtxcs: '每日最高提现次数',
    txsj: '提现时间',
    txgg: '提现公告',
    tzdz: '跳转地址',
    tupian: '图片',
    scyp: '上传音频',
    mingcheng: '名称',
    shiting: '试听',
    bdyp: '绑定音频',
    yinpin: '音频',
    ypbtn: '选择并上传文件',
    yptishi: '只能上传mp3、wav文件，且不超过10MB',
    kjmbtsy: '快捷卖币提示音',
    jjtsy: '基金提示音',
    cztsy: '充值提示音',
    txtsy: '提现提示音',
    sjyzmtsy: '手机验证码提示音',
    yxyzmtsy: '邮箱验证码提示音',
    qdyxyz: '启动邮箱验证',
    smtpfwq: 'SMTP服务器',
    smtpdk: 'SMTP端口',
    fjyxdz: '发件邮箱地址',
    yxyhm: '邮箱用户名',
    yxmm: '邮箱密码',
    fjrmc: '发件人名称',
    qddxyz: '启动短信验证',
    zhanghu: '账户',
    mima: '密码',
    qdipdzjx: '启动IP地址解析',
    lxmc: '类型名称',
    tjxgpz: '添加相关配置',
    xgpz: '相关配置',
    peizhi: '配置',
    ziduan: '字段',
    xiangmu: '项目',
    wenben: '文本',
    mrz: '默认值',
    leixing: '类型',
    pzdh: '配置电话',
    scxy: '上传协议',
    xymc: '协议名称',
    xynr: '协议内容',
    bdxy: '绑定协议',
    zcxy: '注册协议',
    yhdlxy: '用户登录协议',
    jjtzxgxy: '基金投资相关协议'
  },
  xt: {
    sfbs: '身份标识',
    fczh: '封存账号',
    juese: '角色',
    mima: '密码',
    eqpass: '确认密码',
    czmm: '重置密码',
    zcsj: '注册时间',
    zjycdlsj: '最近一次登录时间',
    zsyh: '真实用户',
    xnyh: '虚拟用户',
    quanbu: '全部',
    gly: '管理员',
    kefu: '客服',
    yonghu: '用户',
    quhao: '区号',
    zwmc: '中文名称',
    ywmc: '英文名称',
    mingcheng: '名称',
    quanxian: '权限',
    qxlb: '权限列表',
    yyqx: '已有权限',
    scqx: '删除权限',
    tjqx: '添加权限'
  }
}
